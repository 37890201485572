const GREETING_LOGIN_PAGE = 'Рады видеть!';
const GREETING_REGISTER_PAGE = 'Добро пожаловать!';
const GITHUB_PAGE = 'https://github.com/YellowBlueVase';
const PRAKTIKUM_PAGE = 'https://www.praktikum.ru';
const STATIC_WEB_GIT = 'https://github.com/YellowBlueVase/russian-travel';
const ADAPTIVE_WEB_GIT = 'https://github.com/YellowBlueVase/mesto';
const SPA_WEB_GIT = 'https://github.com/YellowBlueVase/react-mesto-api-full';
const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.moviepicker.nomoredomains.work' : 'http://localhost:3000';
const MOVIES_URL = 'https://api.nomoreparties.co/beatfilm-movies';
const BASIC_API = 'https://api.nomoreparties.co';
const SHORTS_DURATION = 40;

module.exports = {
    GREETING_LOGIN_PAGE,
    GREETING_REGISTER_PAGE,
    GITHUB_PAGE,
    PRAKTIKUM_PAGE,
    STATIC_WEB_GIT,
    ADAPTIVE_WEB_GIT,
    SPA_WEB_GIT,
    BASE_URL,
    MOVIES_URL,
    BASIC_API,
    SHORTS_DURATION,
};
